import React from 'react'
import { Link } from 'react-router-dom';
import { FaPhoneAlt } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import GetCall from './GetCall';
import yandex_img from '../img/marketplaces/yandex_3_white.png';
import wb_img from '../img/marketplaces/wb_white.png';
import ozon_img from '../img/marketplaces/ozon_white.png';
import { IoTime } from "react-icons/io5";


export default function Header() {
  return (
    <header>
      <div className="header_items">
      <Link className='nav_logo_container' to='/main'><div className='nav_logo'></div><div className='nav_logo_text'>SYNDICATE 3D</div></Link>
        <div className='header_nav'>
          <div className='hedear_pop_up'>
            <span>Маркетплейсы</span>
            <ul className='root_item double_submenu dropdown_marketplaces'>
              <li><a href='https://www.ozon.ru/seller/3d-studiya-sindikat-1197788/products/?miniapp=seller_1197788' target='_blank' rel="noopener noreferrer"><img height={'38px'} src={ozon_img} alt='ССыллка на наши товары на озоне' /></a></li>
              <li><a href='https://www.wildberries.ru/seller/4139501' target='_blank' rel="noopener noreferrer"><img style={{marginTop: '17px'}} height={'32x'}src={wb_img} alt='ССыллка на наши товары на вайлдберриз' /></a></li>
              <li><a href='https://market.yandex.ru/business--studiia-3d/80665632' target='_blank' rel="noopener noreferrer"><img style={{marginTop: '17px'}} height={'32px'} src={yandex_img} alt='ССыллка на наши товары на яндекс маркете' /></a></li>
            </ul>
          </div>
          <Link to="/main/warranty">Гарантии</Link>
          <Link to="/main/delivery">Доставка</Link>
          <Link to="/main/about">О нас</Link>
        </div>
        <GetCall />
        <div className="header_addreses">
          <div className='header_adres'>
            <FaMapMarkerAlt />
            <span>Санкт-Петербург Варшавская 23А</span>
          </div>
          <div>
            <FaPhoneAlt />
            <a href="tel:+79110276851">+7 (911) 027-68-51</a>
          </div>
          <div>
            <IoTime />
            <span>10:00-21:00</span>
          </div>
        </div>
      </div>
    </header>
  )
}
