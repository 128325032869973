import React from 'react'
import { Link } from 'react-router-dom';
import imgCreality from '../img/creality_k1.webp';
import imgFilament from '../img/filament.png';
import imgSpareParts from '../img/spareParts.png';
import imgToys from '../img/toys.png';
import { GoTriangleRight } from "react-icons/go";

export default function SideBar() {
  return (
    <div className='sidebar_catalog'>
    <div className='sidebar_catalog_printers'>
      <Link to='/printers'>
      <div className='sidebar_catalog_printers_topmenu'>
        <div className='sidebar_catalog_printers_topmenu_photo sidebar_catalog_topmenu_photo '><img src={imgCreality} alt='Сайдбар фото 3Д принтера ссылка'></img></div>
        <div className='sidebar_catalog_topmenu_tittle sidebar_catalog_topmenu_tittle'><p>3D Принтеры</p></div>
        <GoTriangleRight className='sidebar_alngle_right'/>
      </div></Link>
    </div>
    <Link to='/filament'>
    <div className='sidebar_catalog_filament'>
      <div className='sidebar_catalog_filament_topmenu_photo sidebar_catalog_topmenu_photo'><img src={imgFilament} alt='Сайдбар фото филамент ссылка'></img></div>
      <div className='sidebar_catalog_filament_topmenu_tittle sidebar_catalog_topmenu_tittle'>Филамент</div>
      <GoTriangleRight className='sidebar_alngle_right'/>
      </div></Link>
      <Link to='/spareParts'>
    <div className='sidebar_catalog_spareParts'>
      <div className='sidebar_catalog_spareParts_topmenu_photo sidebar_catalog_topmenu_photo'><img src={imgSpareParts} alt='Сайдбар фото запчасти ссылка'></img></div>
      <div className='sidebar_catalog_spareParts_topmenu_tittle sidebar_catalog_topmenu_tittle'>Запчасти</div>
      <GoTriangleRight className='sidebar_alngle_right'/>
      </div></Link>
      <Link to='/toys'>
    <div className='sidebar_catalog_toys'>
      <div className='sidebar_catalog_toys_topmenu_photo sidebar_catalog_topmenu_photo'><img src={imgToys} alt='Сайдбар фото Игрушки ссылка'></img></div>
      <div className='sidebar_catalog_toys_topmenu_tittle sidebar_catalog_topmenu_tittle'>Наше производство</div>
      <GoTriangleRight className='sidebar_alngle_right'/>
    </div></Link>
</div>
  )
}
